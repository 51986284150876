import React, { useState } from 'react';
import {
  Alert,
  Form,
  Radio,
  Button,
  Upload,
} from 'antd';
import 'antd/es/alert/style/css';
import 'antd/es/radio/style/css';
import 'antd/es/form/style/css';
import 'antd/es/upload/style/css';
import 'antd/es/button/style/css';

import { InboxOutlined } from '@ant-design/icons';
import { config } from './constant';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const normFile = e => {
  console.log('Upload event:', e);
  return e?.file;
};

const UploadPage = () => {
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [infoMsg, setInfoMsg] = useState('');

  const onFinish = async values => {
    setUploading(true);
    setWarnings([]);
    const formData = new FormData();
    for (const name in values) {
      formData.append(name, values[name]);
    }
    const res = await fetch(config.DOMAIN + '/score/upload', {
      method: 'POST',
      body: formData,
    });
    setUploaded(true);
    setUploading(false);
    if (res.status === 200) {
      const data = await res.json();
      setWarnings(data?.warnings ?? []);
      setInfoMsg('上传成功');
    } else if (res.status === 400) {
      const msg = await res.text();
      setInfoMsg(msg);
    } else {
      setInfoMsg('上传失败');
    }
  };

  function Info(props) {
    return (
      <div>
        {props.uploaded ? <Alert key="uploaded" message={infoMsg}></Alert> : <div />}
        {
          warnings.map((w, i) => <Alert type="warning" message={w} key={i} />)
        }
      </div>
    );
  }

  return (
    <div>
      <Info uploaded={uploaded} warnings={warnings}></Info>
      <Form
        name="validate_other"
        {...formItemLayout}
        onFinish={onFinish}
        initialValues={{
          ['source']: 'T',
        }}
        style={{ marginTop: "30px" }}
      >
        <Form.Item name="source" label="表单类型">
          <Radio.Group>
            <Radio value="T">头条订单</Radio>
            <Radio value="W">有赞订单</Radio>
            <Radio value="C">加减积分</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="文件">
          <Form.Item name="file" valuePropName="file" getValueFromEvent={normFile} noStyle>
            <Upload.Dragger name="file" multiple="false" accept=".xlsx" beforeUpload={_ => { return false; }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">点击或拖拽文件到这里</p>
              <p className="ant-upload-hint">支持上传 Excel 表单.</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          {uploading
            ? <Button disabled type="primary" htmlType="submit">上传中</Button>
            : <Button type="primary" htmlType="submit">提交</Button>
          }
        </Form.Item>
      </Form>
    </div>
  );
};

export default UploadPage;
