import React, { useState } from 'react';

import { Layout, Menu } from 'antd';
import 'antd/es/layout/style/css';
import 'antd/es/menu/style/css';

import {
  DesktopOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import UploadPage from './UploadPage';
import UserPage from './UserPage';
import OwnerPage from './OwnerPage';

const { Sider } = Layout;

function App() {
  const logoStyle = {
    margin: '16px',
    color: "white",
    textAlign: "center",
    fontSize: "1.5em",
  };

  const PAGE_UPLOAD = "upload";
  const PAGE_USER_MANAGEMENT = "user-management";
  const PAGE_OWNER_MANAGEMENT = "owner-management";
  const PAGE_ORDER_MANAGEMENT = "order-management";

  const [collapsed, setCollapsed] = useState(false);
  const [page, setPage] = useState(PAGE_USER_MANAGEMENT);

  function Body(props) {
    switch (props.page) {
      case PAGE_UPLOAD:
        return <UploadPage />
      case PAGE_USER_MANAGEMENT:
        return <UserPage />
      case PAGE_OWNER_MANAGEMENT:
        return <OwnerPage />
      case PAGE_ORDER_MANAGEMENT:
        return <h1 style={{fontSize: "3em"}}>开发中！</h1>
      default:
        return <div />
    }
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={c => setCollapsed(c)}>
        <div className="logo" style={logoStyle}>
          大鹏积分管理后台
        </div>
        <Menu theme="dark" mode="inline" onSelect={props => {setPage(props.key)}}>

          <Menu.Item key={PAGE_UPLOAD}>
            <PieChartOutlined />
            <span>表单上传</span>
          </Menu.Item>

          <Menu.Item key={PAGE_USER_MANAGEMENT}>
            <UserOutlined />
            <span>用户管理</span>
          </Menu.Item>

          <Menu.Item key={PAGE_OWNER_MANAGEMENT}>
            <TeamOutlined />
            <span>群主管理</span>
          </Menu.Item>

          <Menu.Item key={PAGE_ORDER_MANAGEMENT}>
            <DesktopOutlined />
            <span>订单管理</span>
          </Menu.Item>
          {/* <SubMenu
            key="sub1"
            title={
              <span>
                <UserOutlined />
                <span>用户管理</span>
              </span>
            }
          >
            <Menu.Item key="3">Tom</Menu.Item>
            <Menu.Item key="4">Bill</Menu.Item>
            <Menu.Item key="5">Alex</Menu.Item>
          </SubMenu> */}
          {/* <SubMenu
            key="sub2"
            title={
              <span>
                <TeamOutlined />
                <span>Team</span>
              </span>
            }
          >
            <Menu.Item key="6">Team 1</Menu.Item>
            <Menu.Item key="8">Team 2</Menu.Item>
          </SubMenu>
          <Menu.Item key="9">
            <FileOutlined />
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Body page={page} />
      </Layout>
    </Layout>
  );
}

export default App;
