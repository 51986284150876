import React, { useState, useEffect } from 'react';
import { Alert, Upload, message } from 'antd';
import { config } from './constant';
import { InboxOutlined } from '@ant-design/icons';

import 'antd/es/alert/style/css';
import 'antd/es/upload/style/css';
import 'antd/es/message/style/css';

const { Dragger } = Upload;

function OwnerPage() {
  const [warnings, setWarnings] = useState([]);
  const [, setOwners] = useState([]);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      const data = await (await fetch(config.DOMAIN + '/score/owners')).json();
      console.log(data);
      if (!ignore) { setOwners(data?.owners ?? []); }
    }
    fetchData();
    return () => { ignore = true; }
  }, []);

  /** @type {import('antd/lib/upload').UploadProps} */
  const props = {
    name: 'file',
    multiple: true,
    accept: '.xlsx',
    action: config.DOMAIN + '/score/bind',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setWarnings(info.file.response?.warnings ?? []);
        message.success(`${info.file.name} 上传成功.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} 上传失败.`);
      }
    }
  }

  return (
    <div style={{ margin: "30px" }}>
      <div>
        {
          warnings.map((w, i) => <Alert type="warning" message={w} key={i} />)
        }
      </div>
      <div>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">上传群主绑定文件</p>
          <p className="ant-upload-hint">
            支持 .xlsx 格式
          </p>
        </Dragger>
      </div>
      {/* <div>
        <List
          bordered
          dataSource={owners.map(o => o.name)}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      </div> */}
    </div>
  )
}

export default OwnerPage;
