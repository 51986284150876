import React, { useState, useEffect } from 'react';
import { config } from './constant';
import { Modal, Form, Row, Col, Input, Button, Table } from 'antd';
import 'antd/es/modal/style/css';
import 'antd/es/row/style/css';
import 'antd/es/col/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/button/style/css';
import 'antd/es/table/style/css';

function UserPage() {
  const [searchParams, setSearchParams] = useState({
    name: '',
    phone: '',
  });
  const [searching, setSearching] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [detailUID, setDetailUID] = useState(null);
  const [userOrders, setUserOrders] = useState([]);

  useEffect(() => {
    const { name, phone } = searchParams;

    if (name === '' && phone === '') {
      return;
    }
    setSearching(true);

    (async () => {
      const params = new URLSearchParams();
      params.append('name', name);
      params.append('phone', phone);
      try {
        const res = await fetch(config.DOMAIN + '/score/user?' + params);
        const data = await res.json();
        setCustomers(data?.customers);
      } catch (e) {
        throw e;
      } finally {
        setSearching(false);
      }
    })();

    return;
  }, [searchParams]);

  useEffect(() => {
    if (detailUID === null) {
      setUserOrders([]);
      return;
    }
    (async () => {
      const params = new URLSearchParams();
      params.append('id', detailUID);
      const res = await fetch(config.DOMAIN + '/score/user_orders?' + params);
      const data = await res.json();
      const { orders } = data;
      setUserOrders(orders);
    })();
  }, [detailUID])

  const COLUMNS = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '积分',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: '电话',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '群主',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: '微信名',
      dataIndex: 'wechat_name',
      key: 'wechat_name',
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <a onClick={() => setDetailUID(record.key)}>查看历史订单</a>
      ),
    },
  ]

  return (
    <div style={{ margin: "60px 40px" }}>
      <Form name="user-search" onFinish={values => setSearchParams({ name: values.name ?? '', phone: values.phone ?? '' })}>
        <Row gutter={24}>
          <Col span={8} key="name">
            <Form.Item name="name" label="姓名">
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={8} key="phone">
            <Form.Item name="phone" label="电话">
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button type="primary" htmlType="submit" loading={searching}>查找</Button>
          </Col>
        </Row>
      </Form>
      <Table dataSource={customers} columns={COLUMNS}></Table>
      <Modal
        title="历史订单"
        visible={detailUID !== null}
        width="80vw"
        onOk={() => setDetailUID(null)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Table dataSource={userOrders}>
          <Table.Column
            title="积分"
            dataIndex="money"
            key="score"
          />
          <Table.Column
            title="来源"
            dataIndex="source"
            key="source"
          />
          <Table.Column
            title="详情"
            dataIndex="detail"
            key="detail"
          />
        </Table>
      </Modal>
    </div>
  );
}

export default UserPage;
